import { useField } from "vee-validate";
import type { ExtractPropTypes, Ref } from "vue";

export const appFormInputProperties = {
  modelValue: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: [String, Number, Array, Object] as any,
    default: undefined,
  },
  label: { type: String, default: undefined },
  name: { type: String, default: undefined },
  placeholder: { type: String, default: undefined },
  type: { type: String, default: "text" },
  icon: { type: String, default: undefined },
  hideDetails: { type: Boolean, default: false },
  autofocus: { type: Boolean, default: false },
  required: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  hint: { type: String, default: undefined },
  pattern: { type: String, default: undefined },
  readonly: { type: Boolean, default: false },
  rules: { type: Object, default: () => ({}) },
  maxLength: { type: Number, default: undefined },
  invalid: { type: Boolean, default: false },
  debounce: { type: Number, default: undefined },
};

let index = 0;

export const uniqueFieldName = () => `field_${index++}`;

export const useAppFormInput = (
  properties: Readonly<ExtractPropTypes<typeof appFormInputProperties>>,
) => {
  const emit = getCurrentInstance()?.emit;
  const name = properties.name ?? properties.label ?? uniqueFieldName();

  const { value, errorMessage } = useField(
    name,
    computed(() => {
      return {
        required: properties.required ?? false,
        email: properties.type === "email",
        maxLength: properties.maxLength
          ? { max: properties.maxLength }
          : undefined,
        matches: properties.pattern
          ? { pattern: properties.pattern }
          : undefined,
        ...properties.rules,
      };
    }),
    {
      initialValue: properties.modelValue,
      syncVModel: false,
      type: properties.type,
    },
  );

  if (properties.debounce) {
    watchDebounced(
      value,
      () => {
        emit?.("update:modelValue", value.value);
      },
      { debounce: properties.debounce },
    );
  } else {
    watch(value, () => {
      emit?.("update:modelValue", value.value);
    });
  }

  watch(
    () => properties.modelValue,
    (updated) => {
      if (updated === value.value) return;
      value.value = updated ?? "";
    },
  );

  const bind = computed(() => {
    return {
      errorMessages: errorMessage.value
        ? [errorMessage.value]
        : // eslint-disable-next-line unicorn/no-nested-ternary
          properties.invalid
          ? ["Invalid value"]
          : [],
      hideDetails: properties.hideDetails,
      placeholder: properties.placeholder,
      label: properties.label,
      prependInnerIcon: properties.icon,
      type: properties.type,
      color: "primary",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      density: "compact" as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      variant: "outlined" as any,
      autofocus: properties.autofocus,
      hint: properties.hint,
      persistentHint: true,
      readonly: properties.readonly,
      menuProps: {
        maxHeight: 250,
        offset: 5,
      },
      maxLength: properties.maxLength,
      persistentCounter: properties.maxLength !== undefined,
      counter: properties.maxLength,
      loading: properties.loading,
      "data-field-name": name,
    };
  });

  return {
    value: value as Ref<string | number>,
    bind,
    errorMessage,
  };
};
